module.exports = {
  sassVariables: {
    primary: '#c7203a',
    secondary: '#1b2023',
    'navbar-back-rotation': '20deg',
    'navbar-link-rotation': '-20deg',
    'login-background': "url('/images/nrg_bg.png')",
    'secondary-background': "''",
  },
  images: {
    loginLogo: '/images/logo-light.png',
    iconLogo: '/images/logo-light.png',
    fullLogo: '/images/logo-dark.png',
    overviewLogo: '/images/logo-dark.png',
    poweredByAdvocate: '/images/powered-by-advocate.png',
  },
}
